// Based on: https://github.com/rt2zz/redux-persist/blob/master/src/stateReconciler/autoMergeLevel2.js
import { PersistConfig } from 'redux-persist';
import { StateReconciler } from 'redux-persist/es/types';

type State = Record<string, any>;

export function autoMerge(maxLevel: number): StateReconciler<any> {
  return function (
    inboundState: State,
    originalState: State,
    reducedState: State,
    { debug }: PersistConfig<any>
  ): State {
    function merge(inboundState: State, originalState: State, reducedState: State, level: number): State {
      const newState = { ...reducedState };
      const isValidInboundState = inboundState && typeof inboundState === 'object';

      // only rehydrate if inboundState exists and is an object
      if (isValidInboundState) {
        Object.keys(inboundState).forEach((key) => {
          // ignore _persist data
          if (key === '_persist') return;
          // if reducer modifies substate, skip auto rehydration
          if (originalState[key] !== reducedState[key]) {
            if (process.env.NODE_ENV !== 'production' && debug)
              console.log('redux-persist/stateReconciler: sub state for key `%s` modified, skipping.', key);
            return;
          }
          if (level < maxLevel && isPlainEnoughObject(reducedState[key])) {
            // Recursively merge the state as long as it's within the level limit
            newState[key] = merge(inboundState[key], originalState[key], newState[key], level + 1);
            return;
          }
          // otherwise hard set
          newState[key] = inboundState[key];
        });
      }

      if (process.env.NODE_ENV !== 'production' && debug && isValidInboundState)
        console.log(`redux-persist/stateReconciler: rehydrated keys '${Object.keys(inboundState).join(', ')}'`);

      return newState;
    }

    return merge(inboundState, originalState, reducedState, 1);
  };
}

function isPlainEnoughObject(obj: any): obj is Record<string, any> {
  return obj !== null && !Array.isArray(obj) && typeof obj === 'object';
}
