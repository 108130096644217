import { CanadianProvinceCodes } from '../../Models/Address';

export default {
  placeholder: {
    streetAddress: 'Entrez une adresse',
    streetNumber: 'Entrez une adresse',
    streetName: 'Entrez un nom de rue',
    apartment: "Entrez votre numéro d'appartement",
    city: 'Entrez votre ville',
    stateProvince: '-- Sélectionnez --',
    postalCode: 'Entrez votre code postal',
    zipCode: 'Entrez votre code zip',
  },
  streetAddress: 'Adresse postale',
  streetNumber: 'Adresse postale',
  streetName: 'Rue',
  city: 'Ville',
  apartment: 'Appartement',
  country: 'Pays',
  province: 'Province',
  state: 'État',
  postalCode: 'Code postal',
  zipCode: 'Code Zip',
  stateOrProvince: {
    CA: {
      [CanadianProvinceCodes.alberta]: 'Alberta',
      [CanadianProvinceCodes.britishColumbia]: 'Colombie-Britannique',
      [CanadianProvinceCodes.manitoba]: 'Manitoba',
      [CanadianProvinceCodes.newBrunswick]: 'Nouveau-Brunswick',
      [CanadianProvinceCodes.newfoundland]: 'Terre-Neuve-et-Labrador',
      [CanadianProvinceCodes.novaScotia]: 'Nouvelle-Écosse',
      [CanadianProvinceCodes.nunavut]: 'Nunavut',
      [CanadianProvinceCodes.northwestTerritories]: 'Territoires du Nord-Ouest',
      [CanadianProvinceCodes.ontario]: 'Ontario',
      [CanadianProvinceCodes.princeEdwardIsland]: 'Île-du-Prince-Édouard',
      [CanadianProvinceCodes.quebec]: 'Québec',
      [CanadianProvinceCodes.saskatchewan]: 'Saskatchewan',
      [CanadianProvinceCodes.yukon]: 'Yukon',
    },
    US: {
      AL: 'Alabama',
      AK: 'Alaska',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DC: 'District of Columbia',
      DE: 'Delaware',
      FL: 'Florida',
      GA: 'Georgia',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PA: 'Pennsylvania',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
      AS: 'American Samoa',
      GU: 'Guam',
      MP: 'Northern Mariana Islands',
      PR: 'Puerto Rico',
      UM: 'United States Minor Outlying Islands',
      VI: 'Virgin Islands',
    },
  },
  countriesByIsoCode: {
    AF: 'Afghanistan',
    AX: '\u00eeles d\u2019\u00c5land',
    AL: 'Albanie',
    DZ: 'Alg\u00e9rie',
    AS: 'Samoa am\u00e9ricaines',
    AD: 'Andorre',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctique',
    AG: 'Antigua-et-Barbuda',
    AR: 'Argentine',
    AM: 'Arm\u00e9nie',
    AW: 'Aruba',
    AU: 'Australie',
    AT: 'Autriche',
    AZ: 'Azerba\u00efdjan',
    BS: 'Bahamas',
    BH: 'Bahre\u00efn',
    BD: 'Bangladesh',
    BB: 'Barbade',
    BY: 'B\u00e9larus',
    BE: 'Belgique',
    BZ: 'Belize',
    BJ: 'B\u00e9nin',
    BM: 'Bermudes',
    BT: 'Bhoutan',
    BO: 'Bolivie',
    BQ: 'Pays-Bas carib\u00e9ens',
    BA: 'Bosnie-Herz\u00e9govine',
    BW: 'Botswana',
    BR: 'Br\u00e9sil',
    IO: 'territoire britannique de l\u2019oc\u00e9an Indien',
    BN: 'Brunei',
    BG: 'Bulgarie',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodge',
    CM: 'Cameroun',
    CA: 'Canada',
    CV: 'Cap-Vert',
    KY: '\u00celes Ca\u00efmans',
    CF: 'R\u00e9publique centrafricaine',
    TD: 'Tchad',
    CL: 'Chili',
    CN: 'Chine',
    CX: '\u00eele Christmas',
    CC: '\u00eeles Cocos (Keeling)',
    CO: 'Colombie',
    KM: 'Comores',
    CG: 'Congo-Brazzaville',
    CD: 'Congo-Kinshasa',
    CK: '\u00eeles Cook',
    CR: 'Costa Rica',
    CI: 'C\u00f4te d\u2019Ivoire',
    HR: 'Croatie',
    CU: 'Cuba',
    CW: 'Cura\u00e7ao',
    CY: 'Chypre',
    CZ: 'Tch\u00e9quie',
    DK: 'Danemark',
    DJ: 'Djibouti',
    DM: 'Dominique',
    DO: 'R\u00e9publique dominicaine',
    EC: '\u00c9quateur',
    EG: '\u00c9gypte',
    SV: 'Salvador',
    GQ: 'Guin\u00e9e \u00e9quatoriale',
    ER: '\u00c9rythr\u00e9e',
    EE: 'Estonie',
    ET: '\u00c9thiopie',
    FK: '\u00eeles Malouines',
    FO: '\u00eeles F\u00e9ro\u00e9',
    FJ: 'Fidji',
    FI: 'Finlande',
    FR: 'France',
    GF: 'Guyane fran\u00e7aise',
    PF: 'Polyn\u00e9sie fran\u00e7aise',
    TF: 'Terres australes fran\u00e7aises',
    GA: 'Gabon',
    GM: 'Gambie',
    GE: 'G\u00e9orgie',
    DE: 'Allemagne',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Gr\u00e8ce',
    GL: 'Groenland',
    GD: 'Grenade',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernesey',
    GN: 'Guin\u00e9e',
    GW: 'Guin\u00e9e-Bissau',
    GY: 'Guyana',
    HT: 'Ha\u00efti',
    VA: 'Cit\u00e9 du Vatican',
    HN: 'Honduras',
    HK: 'R.A.S. chinoise de Hong Kong',
    HU: 'Hongrie',
    IS: 'Islande',
    IN: 'Inde',
    ID: 'Indon\u00e9sie',
    IR: 'Iran',
    IQ: 'Irak',
    IE: 'Irlande',
    IM: '\u00eele de Man',
    IL: 'Isra\u00ebl',
    IT: 'Italie',
    JM: 'Jama\u00efque',
    JP: 'Japon',
    JE: 'Jersey',
    JO: 'Jordanie',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: 'Cor\u00e9e du Nord',
    KR: 'Cor\u00e9e du Sud',
    KW: 'Kowe\u00eft',
    KG: 'Kirghizistan',
    LA: 'Laos',
    LV: 'Lettonie',
    LB: 'Liban',
    LS: 'Lesotho',
    LR: 'Lib\u00e9ria',
    LY: 'Libye',
    LI: 'Liechtenstein',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    MO: 'R.A.S. chinoise de Macao',
    MK: 'Mac\u00e9doine',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaisie',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malte',
    MH: '\u00celes Marshall',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    MU: 'Maurice',
    YT: 'Mayotte',
    MX: 'Mexique',
    FM: 'Micron\u00e9sie',
    MD: 'Moldavie',
    MC: 'Monaco',
    MN: 'Mongolie',
    ME: 'Mont\u00e9n\u00e9gro',
    MS: 'Montserrat',
    MA: 'Maroc',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibie',
    NR: 'Nauru',
    NP: 'N\u00e9pal',
    NL: 'Pays-Bas',
    NC: 'Nouvelle-Cal\u00e9donie',
    NZ: 'Nouvelle-Z\u00e9lande',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nig\u00e9ria',
    NU: 'Niue',
    NF: '\u00eele Norfolk',
    MP: 'Mariannes du Nord',
    NO: 'Norv\u00e8ge',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palaos',
    PS: 'Territoires palestiniens',
    PA: 'Panama',
    PG: 'Papouasie-Nouvelle-Guin\u00e9e',
    PY: 'Paraguay',
    PE: 'P\u00e9rou',
    PH: 'Philippines',
    PN: '\u00eeles Pitcairn',
    PL: 'Pologne',
    PT: 'Portugal',
    PR: 'Porto Rico',
    QA: 'Qatar',
    RE: 'la R\u00e9union',
    RO: 'Roumanie',
    RU: 'Russie',
    RW: 'Rwanda',
    BL: 'Saint-Barth\u00e9lemy',
    SH: 'Sainte-H\u00e9l\u00e8ne',
    KN: 'Saint-Christophe-et-Ni\u00e9v\u00e8s',
    LC: 'Sainte-Lucie',
    MF: 'Saint-Martin (France)',
    PM: 'Saint-Pierre-et-Miquelon',
    VC: 'Saint-Vincent-et-les Grenadines',
    WS: 'Samoa',
    SM: 'Saint-Marin',
    ST: 'Sao Tom\u00e9-et-Principe',
    SA: 'Arabie saoudite',
    SN: 'S\u00e9n\u00e9gal',
    RS: 'Serbie',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapour',
    SX: 'Saint-Martin (Pays-Bas)',
    SK: 'Slovaquie',
    SI: 'Slov\u00e9nie',
    SB: '\u00celes Salomon',
    SO: 'Somalie',
    ZA: 'Afrique du Sud',
    GS: 'G\u00e9orgie du Sud et \u00eeles Sandwich du Sud',
    SS: 'Soudan du Sud',
    ES: 'Espagne',
    LK: 'Sri Lanka',
    SD: 'Soudan',
    SR: 'Suriname',
    SJ: 'Svalbard et Jan Mayen',
    SZ: 'Eswatini',
    SE: 'Su\u00e8de',
    CH: 'Suisse',
    SY: 'Syrie',
    TW: 'Ta\u00efwan',
    TJ: 'Tadjikistan',
    TZ: 'Tanzanie',
    TH: 'Tha\u00eflande',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinit\u00e9-et-Tobago',
    TN: 'Tunisie',
    TR: 'Turquie',
    TM: 'Turkm\u00e9nistan',
    TC: '\u00celes Turques-et-Ca\u00efques',
    TV: 'Tuvalu',
    UG: 'Ouganda',
    UA: 'Ukraine',
    AE: '\u00c9mirats arabes unis',
    GB: 'Royaume-Uni',
    US: '\u00c9tats-Unis',
    UM: '\u00eeles mineures \u00e9loign\u00e9es des \u00c9tats-Unis',
    UY: 'Uruguay',
    UZ: 'Ouzb\u00e9kistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    VG: '\u00eeles Vierges britanniques',
    VI: '\u00eeles Vierges am\u00e9ricaines',
    WB: 'Cisjordanie',
    WF: 'Wallis-et-Futuna',
    EH: 'Sahara occidental',
    YE: 'Y\u00e9men',
    ZM: 'Zambie',
    ZW: 'Zimbabwe',
    'GB-ENG': 'Angleterre',
    'GB-NIR': 'Irlande du Nord',
    'GB-SCT': 'Écosse',
    'GB-WLS': 'Pays de Galles',
  },
} as any;
