import { configureStore, Middleware } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import _ from 'lodash';
import { combineReducers, Reducer, ReducersMapObject } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import { ConsumerFlowStore, createTrackerMiddleware } from '@breathelife/consumer-flow';
import { leadPlatformStore, LeadPlatformStore } from '@breathelife/lead-platform';

import PlanFinderUrls from 'PlanFinder/Navigation/Urls';

import StorePersistenceConfig from './StorePersistenceConfig';

export type Store = ConsumerFlowStore & LeadPlatformStore;

const createRootReducer = (history: History, rootReducer: ReducersMapObject): Reducer =>
  combineReducers({
    router: connectRouter(history),
    ...rootReducer,
    ...leadPlatformStore.RootReducer,
  });

const createPersistedReducer = (history: History, rootReducer: ReducersMapObject): Reducer =>
  persistReducer(StorePersistenceConfig, createRootReducer(history, rootReducer));

const flowUrls = [PlanFinderUrls.questions];

const isTrackedPage = (path: string): boolean => {
  const paths = _.values(PlanFinderUrls);
  const nonFlowPaths = _.difference(paths, flowUrls);

  return nonFlowPaths.includes(path);
};

const createRootMiddlewares = (): Middleware[] => [createTrackerMiddleware(isTrackedPage)];

export default function initializeReduxStore(
  history: History,
  rootReducer: ReducersMapObject,
  middlewares: Middleware[]
) {
  const store = configureStore({
    reducer: createPersistedReducer(history, rootReducer),
    middleware: [...createRootMiddlewares(), ...middlewares],
  });

  const persistor = persistStore(store);

  return { store, persistor };
}
