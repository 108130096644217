import config from '@breathelife/config/frontend';

const textByFieldName: Record<string, string> = {
  streetAddress: '123',
  apartment: '1',
  birthDate: '20000101',
  postalCode: 'M7A1G3',
};

const radioValueByName: Record<string, string> = {
  'adm.residentOfProvinces': 'true',
  'adm.provincialCoverage': 'true',
  'adm.isBNCClient': 'true',
  'adm.canadianTax': 'true',
  'uw.smoker': 'never',
  'equifax.consent': 'true',
  'uw.onYourOwn': 'true',
  OHIP: 'yes',
};

function fillForm(): void {
  const form = document.querySelector('form');
  if (!form) return;

  const submit: HTMLButtonElement | null = form.querySelector('button[type=submit]');
  const textInputs: HTMLInputElement[] = Array.prototype.slice.call(form.querySelectorAll('input[type=text]'));
  const radioInputs: HTMLInputElement[] = Array.prototype.slice.call(form.querySelectorAll('input[type=radio]'));
  const checkboxInputs: HTMLInputElement[] = Array.prototype.slice.call(form.querySelectorAll('input[type=checkbox]'));
  const selects: HTMLSelectElement[] = Array.prototype.slice.call(document.querySelectorAll('select')) || [];

  // Select the first valid option of all selects
  if (selects.length > 0) {
    for (const select of selects) {
      const firstValidOption: HTMLOptionElement | null = select.querySelector('option:not([disabled]):not([hidden])');
      if (!firstValidOption) return;

      firstValidOption.selected = true;
      select.value = firstValidOption.value;

      firstValidOption.dispatchEvent(new Event('change', { bubbles: true }));
      select.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }

  // Select the defined radio option, defaulting to the last option of radios
  if (radioInputs.length > 0) {
    let inputToClick = radioInputs[radioInputs.length - 1];
    const lookForValue = radioValueByName[radioInputs[0].name];

    if (typeof lookForValue !== 'undefined') {
      const specifiedInput = radioInputs.find((input) => input.value === lookForValue);
      if (specifiedInput) inputToClick = specifiedInput;
    }

    inputToClick.click();
  }

  // Select the last option of checkboxes
  if (checkboxInputs.length > 0) {
    checkboxInputs[checkboxInputs.length - 1].click();
  }

  // Fill text inputs
  if (textInputs.length > 0) {
    for (const input of textInputs) {
      // There's some really weird issues here with semantic-ui inputs not detecting onchange...
      const text = textByFieldName[input.name as string] || 'Autofilled value';
      input.dispatchEvent(new Event('focus', { bubbles: true }));
      input.value = text;
      // @ts-ignore
      input.dispatchEvent(new Event('change', { target: { value: text }, cancellable: true }));
      input.dispatchEvent(new Event('blur', { bubbles: true }));
    }
  }

  submit && submit.click();
}

function onKeypress(e: KeyboardEvent): void {
  if (e.key === 'K' && e.ctrlKey && e.shiftKey) {
    fillForm();
  }
}

export function enableDevFormFiller(): void {
  if (!config.get('debug.enableDevFormFiller')) return;
  document.addEventListener('keypress', onKeypress);
}

export function disableDevFormFiller(): void {
  document.removeEventListener('keypress', onKeypress);
}
