import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Middleware } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'semantic-ui-css/semantic.min.css';

import { consumerFlowStore } from '@breathelife/consumer-flow';
import { leadPlatformStore } from '@breathelife/lead-platform';
import { ErrorBoundary } from '@breathelife/ui-components';

import { enableDevFormFiller } from '@direct/frontend-common/dist/Helpers/DevFormFiller';
import { setTranslations, textIfExists } from '@direct/frontend-common/dist/Localization/Localizer';
import { initSentry } from '@direct/frontend-common/dist/Monitoring';
import history from '@direct/frontend-common/dist/Navigation/history';

import carrierTranslations from 'Localization';

import initializeReduxStore from './Redux/Store';
import Root from './Root';

initSentry();
enableDevFormFiller();

history.listen(() => window.scroll(0, 0));
setTranslations(carrierTranslations);

const rootReducer = consumerFlowStore.RootReducer;
const consumerFlowMiddlewares = consumerFlowStore.middlewares(history);
const leadPlatformMiddlewares = leadPlatformStore.middlewares(history);
const middlewares = [...consumerFlowMiddlewares, ...leadPlatformMiddlewares];

const { store, persistor } = initializeReduxStore(history, { ...rootReducer }, middlewares as Middleware[]);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ErrorBoundary textIfExists={textIfExists}>
          <Root customHistory={history} />
        </ErrorBoundary>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
