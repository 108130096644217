import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import featureFlag, { FeatureFlag } from '@breathelife/feature-flag';

import ConsumerFlowContainer from '../ConsumerFlow/ConsumerFlowContainer';
import Urls from './Navigation/Urls';

export default function (): React.ReactElement | null {
  const [enableHeaderProductSummary, setEnableHeaderProductSummary] = useState(false);
  const isInitialized = featureFlag.isInitialized();

  useEffect(() => {
    if (!isInitialized) return;

    setEnableHeaderProductSummary(featureFlag.get(FeatureFlag.enableHeaderProductSummary));
  }, [isInitialized]);

  return (
    <Switch>
      <Route
        path={Urls.home}
        render={() => <ConsumerFlowContainer enableHeaderProductSummary={enableHeaderProductSummary} />}
      />
    </Switch>
  );
}
