import { IConfig, IMutableContext, UnleashClient } from 'unleash-proxy-client';

export class UnleashSdk {
  private static unleashInstance: UnleashClient | undefined;
  private static initialized = false;

  /**
   * This method initializes the feature flag solutions.
   * How we use it can be seen in `init` function of `shared/feature-flags-frontend/src/index.ts`
   * @param {IConfig} options are the config values required to initialize unleash.
   * @param {IMutableContext} context accepts user properties which can be used to determine a flag's value
   * @throw if environment is not provided
   */
  public static async initializeUnleash(options: IConfig, context: IMutableContext = {}): Promise<void> {
    if (!options.environment) {
      throw new Error('environment is required');
    }

    if (!this.unleashInstance) {
      this.unleashInstance = new UnleashClient(options);

      await this.unleashInstance.updateContext(context);

      await this.unleashInstance.start();

      this.initialized = true;
    }
  }

  /**
   * @returns {boolean} if feature flags unleash have been initialized or not
   */
  public static isInitialized(): boolean {
    return this.initialized;
  }

  /**
   * This method returns the value of the flag specified by param `name`.
   * If the flag doesn't exist in unleash, it returns false
   * @param {string} name of the flag whose value you want to check
   * @returns {boolean} the value of the flag specified by param `name` or false if the flag doesn't exist in unleash
   * @throw if unleash instance is not initialized
   */
  public static get(name: string): boolean {
    if (!UnleashSdk.isInitialized() || !this.unleashInstance) {
      throw new Error('Feature Flags from unleash are not initialized');
    }

    return this.unleashInstance.isEnabled(name);
  }
}
