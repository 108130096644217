import Address from './Address/french';
import Agents from './Agents/french';
import ErrorPage from './ErrorPage/french';
import Header from './Header/french';
import Pdf from './Pdf/french';
import QuoteApplication from './QuoteApplication/french';
import SessionExpired from './SessionExpired/french';
import ValidationErrors from './ValidationErrors/french';

export default {
  loading: 'téléchargement...',
  pdf: Pdf,
  leftPanel: {
    clientAge: '{{age}} ans',
    clientGender: {
      male: 'Homme',
      female: 'Femme',
    },
    clientSmoker: {
      true: 'Fumeur',
      false: 'Non Fumeur',
    },
  },
  allRightReserved: 'TOUS DROITS RÉSERVÉS {{year}}',
  date: {
    year: 'Année',
    month: 'Mois',
    day: 'Jour',
  },
  confirm: 'Confirmer',
  understand: 'Je comprends',
  header: Header,
  agent: Agents,
  validationErrors: ValidationErrors,
  quoteApplication: QuoteApplication,
  sessionExpired: SessionExpired,
  address: Address,
  errorPage: ErrorPage,
  month: {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
  },
  back: 'Revenir en arrière',
  arrowBack: 'Flèche arrière',
  arrowForward: "Flèche vers l'avant",
  editResponse: 'Modifier la réponse',
  error: 'Erreur',
  logo: 'Logo de {{companyName}}',
  pleaseAnswer: "Veuillez répondre à la question d'abord.",
  phoneNumber: 'Téléphone',
  search: 'Chercher',
  formats: {
    date: "D MMM 'YY",
    time: 'H:mm',
    at: 'à',
  },
  validation: {
    optional: 'Facultatif',
    postalCode: 'Le code postal doit être au format A1A 1A1 ou A1A1A1',
    invalidEmail: 'Courriel invalide',
    pastDate: "Merci d'entrer une date antérieure à aujourd'hui",
    phoneNumber: "Merci d'entrer un numéro de téléphone valide à 10 chiffres",
    phoneNumberAreaCode: "Merci d'entrer un indicatif régional valide",
    decimal: "Merci d'entrer un nombre (maximum 2 décimales)",
    isTrue: "Veuillez accepter les conditions d'utilisation",
    dateFormat: 'Veuillez entrer une date valide',
    ssnLength: 'Veuillez entrer un numéro de sécurité sociale valide à 9 chiffres (par exemple : 123-45-6789)',
  },
};
