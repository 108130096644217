import dayjs from 'dayjs';
import I18n, { ToCurrencyOptions, TranslateOptions } from 'i18n-js';
import _ from 'lodash';
import queryString from 'query-string';

import config from '@breathelife/config/frontend';
import { Language, LanguageRegion } from '@breathelife/types';

import baseTranslations from './';

export const DEFAULT_LOCALE = 'en-CA';

let langSwitcher = config.get<Partial<Record<Language, string>> | null>('carrier.langSwitcher');

export function setLangSwitcher(newLangSwitcher: Partial<Record<Language, string>>) {
  langSwitcher = newLangSwitcher;
}

const langBasedOnUrl = () => {
  if (!langSwitcher) return;
  if (langSwitcher.en && _.includes(window.location.origin, langSwitcher.en)) return Language.fr;
  if (langSwitcher.fr && _.includes(window.location.origin, langSwitcher.fr)) return Language.en;
};

export const getCurrentLocale = () => {
  const queryStringLanguage = queryString.parse(window.location.search).lang;
  const locale =
    Language[queryStringLanguage as keyof typeof Language] ||
    langBasedOnUrl() ||
    window.localStorage.getItem('locale') ||
    window.navigator.userLanguage ||
    window.navigator.language ||
    DEFAULT_LOCALE;

  return locale.split('-')[0];
};

let currentLocale = getCurrentLocale();

export function setTranslations(carrierTranslations: Partial<Record<Language, unknown>>) {
  I18n.translations = {
    en: _.merge({}, baseTranslations.en, carrierTranslations.en),
    fr: _.merge({}, baseTranslations.fr, carrierTranslations.fr),
  };
}

export function setLocale(locale: string = getCurrentLocale()) {
  window.localStorage.setItem('locale', locale);
  currentLocale = locale as Language;
  I18n.locale = locale;
}

setLocale(currentLocale);

export const shortLocale = () => (currentLocale.indexOf(Language.fr) !== -1 ? Language.fr : Language.en);

export const getLanguageRegion = (): LanguageRegion =>
  currentLocale.indexOf(Language.fr) !== -1 ? LanguageRegion.frCa : LanguageRegion.enCa;

I18n.fallbacks = true;
I18n.translations = baseTranslations;
I18n.defaultLocale = DEFAULT_LOCALE;

export function text(value: string, parameters?: TranslateOptions) {
  return I18n.t(value, parameters);
}

// Returns `undefined` if a text isn't defined instead of using I18n's fallback system
export function textIfExists(value: string, parameters?: TranslateOptions) {
  return I18n.t(value, { ...parameters, defaults: [{ message: '' }] }) || undefined;
}

export function localizedConfig<T>(value: string): T {
  return config.get(`${value}.${shortLocale()}`);
}

export function toCurrency(value: number | null, options: ToCurrencyOptions = {}): string {
  if (value == null) {
    return 'N/A';
  }

  let currencyFormat = '%u%n';
  let delimiter = ',';
  let separator = '.';
  if (shortLocale() === 'fr') {
    currencyFormat = '%n %u';
    delimiter = ' ';
    separator = ',';
  }

  return I18n.toCurrency(value, _.merge(options, { delimiter, separator, format: currencyFormat }));
}

export function getLanguageSwitcherURL() {
  if (langSwitcher) {
    return langSwitcher[shortLocale()];
  }
  return `${window.location.origin}${shortLocale() === 'en' ? '?lang=fr' : '?lang=en'}`;
}

export function toRoundedCurrency(amount: number | null): string {
  return toCurrency(amount, { precision: 0 });
}

export function toNumber(value: number, parameters: any = {}) {
  return I18n.toNumber(value, parameters);
}

export function formatDate(date: Date | string) {
  return dayjs(date).format(text('formats.date'));
}

export function formatTime(date: Date | string) {
  return dayjs(date).format(text('formats.time'));
}

export function formatDatetime(date: Date | string) {
  return `${formatDate(date)} ${text('formats.at')} ${formatTime(date)}`;
}

export function a11yPageTitle(title: string) {
  const pageTitle = text(`a11y.pageTitle.${title}`);
  a11yNavigationPageTitle(pageTitle);
  return pageTitle;
}

export function a11yNavigationPageTitle(pageTitle: string) {
  return text('a11y.navigation', { pageTitle });
}
