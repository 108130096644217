import Address from './Address/english';
import Agents from './Agents/english';
import ErrorPage from './ErrorPage/english';
import Header from './Header/english';
import Pdf from './Pdf/english';
import QuoteApplication from './QuoteApplication/english';
import SessionExpired from './SessionExpired/english';
import ValidationErrors from './ValidationErrors/english';

export default {
  loading: 'loading...',
  pdf: Pdf,
  leftPanel: {
    clientAge: '{{age}} years old',
    clientGender: {
      male: 'Male',
      female: 'Female',
    },
    clientSmoker: {
      true: 'Smoker',
      false: 'Non Smoker',
    },
  },
  allRightReserved: 'ALL RIGHTS RESERVED {{year}}',
  date: {
    year: 'Year',
    month: 'Month',
    day: 'Day',
  },
  confirm: 'Confirm',
  understand: 'I understand',
  header: Header,
  agent: Agents,
  validationErrors: ValidationErrors,
  quoteApplication: QuoteApplication,
  sessionExpired: SessionExpired,
  address: Address,
  errorPage: ErrorPage,
  month: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  back: 'Back',
  arrowBack: 'Back arrow',
  arrowForward: 'Forward arrow',
  editResponse: 'Edit response',
  error: 'Error',
  logo: '{{companyName}} logo',
  pleaseAnswer: 'Please answer the question first',
  phoneNumber: 'Phone Number',
  search: 'Search',
  formats: {
    date: "D MMM 'YY",
    time: 'H:mm',
    at: 'at',
  },
  validation: {
    optional: 'Optional',
    postalCode: 'The postal code needs to be in the following format: A1A 1A1 or A1A1A1',
    invalidEmail: 'Invalid email',
    pastDate: 'Please enter a date earlier than today',
    phoneNumber: 'Please enter a valid phone number with 10 digits',
    phoneNumberAreaCode: 'Please enter a valid area code',
    decimal: 'Please enter a number (with up to 2 decimals)',
    isTrue: 'Please accept the terms',
    dateFormat: 'Please enter a valid date',
    ssnLength: 'Please enter a valid social security number with 9 digits(e.g: 123-45-6789)',
  },
};
