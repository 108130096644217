import landingPageImage from '../../images/genericLandingPage.jpg';
import backArrow from '../../images/icon-back-arrow.svg';
import logoCompactBlue from '../../images/logo-compact-blue.png';
import logoCompact from '../../images/logo-compact.svg';
import logoWhite from '../../images/logo-white.svg';
import logo from '../../images/logo.svg';
import bartellFinancialPartners from '../../images/mga/logo-bartell-financial-partners.png';
import danielAdvisorAgency from '../../images/mga/logo-daniel-advisor-agency.png';
import schultzBrokersGroup from '../../images/mga/logo-schultz-brokers-group.png';

export const ImageFilenames = {
  agent: {
    logoWhite,
    logoCompact,
    bartellFinancialPartners,
    danielAdvisorAgency,
    schultzBrokersGroup,
  },
  carrier: {
    logo: logo,
  },
  planFinder: {
    backArrow,
    logo,
  },
  general: {
    arrowTheme: backArrow,
  },
  product: {
    logo: {
      en: logoCompactBlue,
      fr: logoCompactBlue,
    },
  },
  genericPublicLink: {
    landingPageImage: landingPageImage,
  },
};
