import GenericLinkLanding from './GenericLinkLanding/english';
import HomePage from './HomePage/english';
import Pdf from './Pdf/english';

export default {
  home: HomePage,
  pdf: Pdf,
  genericLinkLanding: GenericLinkLanding,
  errorBoundary: {
    title: 'Oops, something went wrong!',
    subtitle: `We have been notified and are looking into the issue. Please refresh your browser or wait a few minutes.`,
  },
  carriers: {
    breathelife: 'Breathe Life',
  },
};
