import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {
  AgeRoundingType,
  DateTimeReference,
  DateUnit,
  TimeRoundingType,
  TimeUnitRoundingType,
  Timezone,
} from '@breathelife/types';

dayjs.extend(utc);
dayjs.extend(timezone);

/** Formats a date in the questionnaire field value format */
export function dateToFieldValue(date: Date): string {
  return dayjs(date).format('YYYY-MM-DD');
}

/** Returns the date of the birthday happening the year of the provided referenceDate (current year if omitted) */
export function getCurrentYearBirthdayDate(birthDate: Date, referenceDate: Date = new Date()): Date {
  return dayjs(birthDate).set('year', dayjs(referenceDate).year()).toDate();
}

/** Returns the date of the last birthday to happen before the referenceDate (current date if omitted) */
export function getLastBirthdayDate(birthDate: Date, referenceDate: Date = new Date()): Date {
  const currentYearBirthday: Date = getCurrentYearBirthdayDate(birthDate, referenceDate);

  if (referenceDate < currentYearBirthday) {
    return dayjs(currentYearBirthday).subtract(1, 'year').toDate();
  }
  return currentYearBirthday;
}

/** Returns the date of the next birthday to happen after the referenceDate (current date if omitted) */
export function getNextBirthdayDate(birthDate: Date, referenceDate: Date = new Date()): Date {
  const currentYearBirthday: Date = getCurrentYearBirthdayDate(birthDate, referenceDate);

  if (referenceDate < currentYearBirthday) {
    return currentYearBirthday;
  }
  return dayjs(currentYearBirthday).add(1, 'year').toDate();
}

/** Returns the date of the birthday that's closest to the referenceDate (current date if omitted)
 * The "closest" is not calculated in number of days, but rather in number of full months  */
export function getClosestBirthdayDate(birthDate: Date, referenceDate: Date = new Date()): Date {
  // Here we might be temped to calculate the number of full months since the last birthday, rather than calculate
  // the number of full months since birth then apply a modulo 12. These two operations are not quite the same due
  // to leap year edge cases. We must really calculate full months _since birth_ to have the desired output.
  const fullMonthsSinceBirth: number = dayjs(referenceDate).diff(dayjs(birthDate), 'month');
  const fullMonthsSinceLastFullYear: number = fullMonthsSinceBirth % 12;

  const lastFullMonthDate: Dayjs = dayjs(birthDate).add(fullMonthsSinceBirth, 'month');
  const fullDaysSinceLastFullMonth: number = dayjs(referenceDate).diff(lastFullMonthDate, 'day');

  // The condition is based on your age in years, months and days, e.g. "30 years, 3 months and 17 days old"
  return fullMonthsSinceLastFullYear > 6 || (fullMonthsSinceLastFullYear === 6 && fullDaysSinceLastFullMonth > 0)
    ? getNextBirthdayDate(birthDate, referenceDate)
    : getLastBirthdayDate(birthDate, referenceDate);
}

/** Returns the birthday date that age should be calculated from depending on the roundingType */
function getBirthdayDate(birthDate: Date, roundingType: AgeRoundingType, referenceDate: Date = new Date()): Date {
  switch (roundingType) {
    case AgeRoundingType.lastBirthday: {
      return getLastBirthdayDate(birthDate, referenceDate);
    }
    case AgeRoundingType.nextBirthday: {
      return getNextBirthdayDate(birthDate, referenceDate);
    }
    case AgeRoundingType.closestBirthday: {
      return getClosestBirthdayDate(birthDate, referenceDate);
    }
    default: {
      throw Error('Unsupported rounding type');
    }
  }
}

/** Returns the age in years at the referenceDate (current date if omitted) */
export function getAge(birthDate: Date, roundingType: AgeRoundingType, referenceDate: Date = new Date()): number {
  const birthdayDate: Date = getBirthdayDate(birthDate, roundingType, referenceDate);
  return Math.round(dayjs(birthdayDate).diff(dayjs(birthDate), 'year', true));
}

export function getDateFromReferenceValue(dateReference: DateTimeReference | string, timezone: Timezone): Dayjs {
  switch (dateReference) {
    case DateTimeReference.currentDateTime:
      return dayjs.tz(dayjs.utc(), timezone.name);
    default:
      return dayjs.tz(dateReference, timezone.name);
  }
}

export function formatDate(date: Date, format: string): string | undefined {
  const dateToFormatIsValid = dayjs(date).isValid();
  if (!dateToFormatIsValid) {
    return undefined;
  }

  const formatted = dayjs(date).format(format);
  if (!dayjs(formatted).isValid()) {
    throw Error('Cannot format date; invalid format provided');
  }
  return formatted;
}

/** Returns the date representing the minimum amount of time (with the unit being the specified DateUnit) greater
 *  than the value parameter (for example, age) at the specified referenceDate after the value gets rounded
 *  Ex:
 *     - For 18 years with lastBirthday rounding, will return referenceDate - 18 years
 *     - For 18 years with nextBirthday rounding, will return referenceDate - 17 years
 *     - For 18 years with closestBirthday rounding, will return referenceDate - 18 years + 6 months - 1 day */
export function getLatestDateRespectingShiftValue(
  shiftValue: number,
  unit: DateUnit,
  roundingType: TimeRoundingType,
  referenceDate: Dayjs
): Date {
  const { ageRoundingType, roundedValue } = timeUnitRoundingToAgeRounding(roundingType, shiftValue);

  // DateUnit and UnitType have overlapping types, making it possible to do this coercion
  const convertedUnit = unit;
  const shiftedDate = dayjs(referenceDate).subtract(roundedValue, convertedUnit);
  switch (ageRoundingType) {
    case AgeRoundingType.none: {
      return shiftedDate.toDate();
    }
    case AgeRoundingType.lastBirthday: {
      return shiftedDate.toDate();
    }
    case AgeRoundingType.nextBirthday: {
      return shiftedDate.add(1, 'year').toDate();
    }
    case AgeRoundingType.closestBirthday: {
      let minDate: Dayjs = shiftedDate.add(6, 'month').subtract(1, 'day');

      if (referenceDate.date() > minDate.daysInMonth()) {
        // Special case when the reference date and birth date months don't have the same number of days
        // Covers cases where the min birth date would fall February 29-31 or June 31
        minDate = dayjs(minDate).add(1, 'day');
      }

      return minDate.toDate();
    }
    default: {
      throw Error('Unsupported rounding type');
    }
  }
}

/** Returns the date representing the maximum amount of time (with the unit being the specified DateUnit) smaller
 *  than the value parameter (for example, age) at the specified referenceDate after the value gets rounded
 *  Ex:
 *     - For 55 years with lastBirthday rounding, will return referenceDate - 56 years + 1 day
 *     - For 55 years with nextBirthday rounding, will return referenceDate - 55 years + 1 day
 *     - For 55 years with closestBirthday rounding, will return referenceDate - 55 years - 6 months */
export function getEarliestDateRespectingShiftValue(
  shiftValue: number,
  unit: DateUnit,
  roundingType: TimeRoundingType,
  referenceDate: Dayjs
): Date {
  const { ageRoundingType, roundedValue } = timeUnitRoundingToAgeRounding(roundingType, shiftValue);
  // DateUnit and UnitType have overlapping types, making it possible to do this coercion
  const convertedUnit = unit;
  const shiftedDate = dayjs(referenceDate).subtract(roundedValue, convertedUnit);
  switch (ageRoundingType) {
    case AgeRoundingType.none: {
      return shiftedDate.toDate();
    }
    case AgeRoundingType.lastBirthday: {
      return shiftedDate.subtract(1, 'year').add(1, 'day').toDate();
    }
    case AgeRoundingType.nextBirthday: {
      return shiftedDate.add(1, 'day').toDate();
    }

    case AgeRoundingType.closestBirthday: {
      let maxDate: Dayjs = shiftedDate.subtract(6, 'month');

      if (referenceDate.date() > maxDate.daysInMonth()) {
        // Special case when the reference date and birth date months don't have the same number of days
        // Covers cases where the max birth date would fall February 29-31 or June 31
        maxDate = dayjs(maxDate).add(1, 'day');
      }

      return maxDate.toDate();
    }
    default: {
      throw Error('Unsupported rounding type');
    }
  }
}

/** Returns a rounded time value and AgeRoundingType when a TimeUnitRoundingType is used */
export function timeUnitRoundingToAgeRounding(
  roundingType: TimeRoundingType,
  timeValue: number
): {
  ageRoundingType: AgeRoundingType;
  roundedValue: number;
} {
  let ageRoundingType: AgeRoundingType;
  let roundedValue: number;

  switch (roundingType) {
    case TimeUnitRoundingType.none: {
      ageRoundingType = AgeRoundingType.none;
      roundedValue = timeValue;
      break;
    }
    case TimeUnitRoundingType.nextUnit: {
      ageRoundingType = AgeRoundingType.lastBirthday;
      roundedValue = timeValue - 1;
      break;
    }
    default: {
      ageRoundingType = roundingType;
      roundedValue = timeValue;
      break;
    }
  }

  return {
    ageRoundingType,
    roundedValue,
  };
}
