import { domainFilter } from './DomainFilter';
import { messageFilter } from './MessageFilter';
import { EventModifier, BeforeSend, DomainFilterConfig, SentryEventFiltersConfig, MessageFilterConfig } from './Types';

function createDomainFilter(domainFilterConfig?: DomainFilterConfig[]): EventModifier {
  if (domainFilterConfig === undefined) {
    return () => undefined;
  }

  return (event) => {
    return domainFilter(event, domainFilterConfig);
  };
}

function createMessageFilter(messageFilterConfig?: MessageFilterConfig[]): EventModifier {
  if (messageFilterConfig === undefined) {
    return () => undefined;
  }

  return (_event, hint) => {
    return messageFilter(hint, messageFilterConfig);
  };
}

export function createBeforeSend(sentryEventFilters: SentryEventFiltersConfig): BeforeSend {
  const domainFilter = createDomainFilter(sentryEventFilters.domains);
  const messageFilter = createMessageFilter(sentryEventFilters.messages);

  const filters = [domainFilter, messageFilter];

  return function beforeSend(event, hint) {
    for (const filter of filters) {
      const eventModifier = filter(event, hint);

      if (eventModifier !== undefined) {
        event.fingerprint = [eventModifier.fingerprint];
        event.level = eventModifier.severity;
        return event;
      }
    }

    return event;
  };
}
