import GenericLinkLanding from './GenericLinkLanding/french';
import HomePage from './HomePage/french';
import Pdf from './Pdf/french';

export default {
  home: HomePage,
  pdf: Pdf,
  genericLinkLanding: GenericLinkLanding,
  errorBoundary: {
    title: 'Oups, un problème est survenu!',
    subtitle: `Nous avons été informés et étudions le problème. Veuillez actualiser votre navigateur ou patienter quelques minutes.`,
  },
  carriers: {
    breathelife: 'Breathe Life',
  },
};
