import { History } from 'history';
import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import config from '@breathelife/config/frontend';
import { FeatureFlagsFrontend } from '@breathelife/feature-flags-frontend';
import { ErrorBoundary, FocusControl, RootLoader } from '@breathelife/ui-components';

import { textIfExists } from '@direct/frontend-common/dist/Localization/Localizer';
import { ProductName, setSentryProduct } from '@direct/frontend-common/dist/Monitoring';

import DynamicPdfContainer from './DynamicPdf/DynamicPdfContainer';
import LeadPlatform from './LeadPlatform/LeadPlatformContainer';
import PlanFinder from './PlanFinder';

export type Props = {
  customHistory: History;
};

const Urls = {
  planFinder: {
    home: '/plan',
  },
  leadPlatform: {
    home: '/leads',
    pro: '/pro',
  },
  pdf: '/pdf',
};

class Root extends Component<Props, { featureFlagInitialized: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { featureFlagInitialized: false };
  }

  async componentDidMount(): Promise<void> {
    type FeatureFlagsInfraConfig = { clientKey: string; environment: string; url: string | null };

    const featureFlags = await FeatureFlagsFrontend.init({
      ...config.get<FeatureFlagsInfraConfig>('infra.featureFlags'),
      appName: config.get('carrier.id'),
      legacyFeatureFlags: config.get('featureFlags'),
    });

    this.setState({ featureFlagInitialized: true });

    console.info(`Feature flags initialized`, featureFlags);
  }

  render(): React.ReactElement | null {
    if (!this.state.featureFlagInitialized) {
      return <RootLoader />;
    }
    return (
      <ErrorBoundary textIfExists={textIfExists}>
        <FocusControl>
          <Router history={this.props.customHistory}>
            <Switch>
              <Route
                path={Urls.planFinder.home}
                render={() => {
                  setSentryProduct(ProductName.ConsumerFlow);
                  return <PlanFinder />;
                }}
              />
              <Route
                path={[Urls.leadPlatform.home, Urls.leadPlatform.pro]}
                render={() => {
                  setSentryProduct(ProductName.LeadPlatform);
                  return <LeadPlatform />;
                }}
              />
              <Route path={Urls.pdf} component={DynamicPdfContainer} />
              <Redirect to={Urls.planFinder.home} />
            </Switch>
          </Router>
        </FocusControl>
      </ErrorBoundary>
    );
  }
}

export default Root;
