import { css } from 'styled-components';

export const globalStyle = css`
  // This is required to prevent the content from jumping and flickering on iOS
  html,
  body {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  body {
    background-color: ${(props) => props.theme.colors.grey[20]};
  }

  body,
  p {
    font-family: ${(props) => {
      // @ts-ignore TODO: fix type
      return props.theme.fonts.family.fallbacks;
    }} !important;
  }

  a {
    color: ${(props) => {
      // @ts-ignore TODO: fix type
      return props.theme.colors.base.primary;
    }};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(props) => {
      // @ts-ignore TODO: fix type
      return props.theme.fonts.family.title;
    }};
    font-weight: normal;
  }

  button {
    cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid
      ${(props) => {
        // @ts-ignore TODO: fix type
        return props.theme.colors.base.primary;
      }} !important ;
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) => {
        // @ts-ignore TODO: fix type
        return props.theme.colors.base.softPrimary;
      }}
      inset !important ;
    box-shadow: 0 0 0px 1000px
      ${(props) => {
        // @ts-ignore TODO: fix type
        return props.theme.colors.base.softPrimary;
      }}
      inset !important ;
  }
`;
