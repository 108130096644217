export const grey = {
  100: '#000000',
  90: '#333333',
  80: '#757575',
  70: '#999999',
  60: '#BCBCBC',
  50: '#E6E6E6',
  40: '#EEEEEE',
  30: '#f6f6f6',
  20: '#fbfbfb',
  10: '#fdfdfd',
  0: '#ffffff',
};

export const primary = {
  default: '#476CC6',
  90: '#1C2B4F',
  80: '#1C2B4F',
  70: '#2B4177',
  60: '#39569E',
  50: '#476CC6',
  40: '#6C89D1',
  30: '#DAE2F4',
  20: '#EDF0F9',
  10: '#EDF0F9',
};

export const secondary = {
  default: '#FFCE84',
  90: '#665235',
  80: '#665235',
  70: '#997C4F',
  60: '#CCA56A',
  50: '#FFCE84',
  40: '#FFE2B5',
  30: '#FFF5E6',
  20: '#FFFAF3',
  10: '#FFFAF3',
};

export const tertiary = {
  default: '#EF86A1',
  90: '#603640',
  80: '#603640',
  70: '#8F5061',
  60: '#BF6B81',
  50: '#EF86A1',
  40: '#F5B6C7',
  30: '#FCE7EC',
  20: '#FDF3F6',
  10: '#FDF3F6',
};
