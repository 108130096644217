import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LoadingPageMessages = {
  title: string;
  subtitle?: string;
  descriptions?: string[];
};

export type NavigationState = {
  a11yMessage: string;
  basePath: string;
  loadingPage: {
    isVisible: boolean;
    messages?: LoadingPageMessages;
  };
};

type A11yNavigationPayload = {
  a11yMessage: string;
};

type BasePathNavigationPayload = {
  basePath: string;
};

type LoadingPageNavigationPayload = {
  messages?: LoadingPageMessages;
  isVisible: boolean;
};

type ActionsType = {
  setA11yMessage: (state: NavigationState, { payload }: PayloadAction<A11yNavigationPayload>) => void;
  setBasePath: (state: NavigationState, { payload }: PayloadAction<BasePathNavigationPayload>) => void;
  setLoadingPage: (state: NavigationState, { payload }: PayloadAction<LoadingPageNavigationPayload>) => void;
};

const defaultState = {
  a11yMessage: '',
  basePath: '',
  loadingPage: {
    isVisible: false,
  },
};

export const navigationSlice = createSlice<NavigationState, ActionsType>({
  name: 'navigation',
  initialState: defaultState,
  reducers: {
    setA11yMessage(state: NavigationState, { payload }: PayloadAction<A11yNavigationPayload>) {
      state.a11yMessage = payload.a11yMessage;
    },
    setBasePath(state: NavigationState, { payload }: PayloadAction<BasePathNavigationPayload>) {
      state.basePath = payload.basePath;
    },
    setLoadingPage(state: NavigationState, { payload }: PayloadAction<LoadingPageNavigationPayload>) {
      state.loadingPage = payload;
    },
  },
});
