import { Severity, EventHint } from '@sentry/browser';

import { EventModifierReturnType, MessageFilterConfig } from './Types';

function extractMessageFromHint(hint?: EventHint) {
  if (hint === undefined || hint.originalException === null || hint.originalException === undefined) {
    return;
  }

  if (typeof hint.originalException === 'string') {
    return hint.originalException;
  }

  return hint.originalException.message;
}

export function messageFilter(
  hint: EventHint | undefined,
  messageFilterConfig: MessageFilterConfig[]
): EventModifierReturnType {
  const message = extractMessageFromHint(hint);
  if (message === undefined) {
    return;
  }

  const filter = messageFilterConfig.find((filter) => {
    return message.includes(filter.message);
  });

  if (filter === undefined) {
    return;
  }

  return {
    fingerprint: filter.fingerprint,
    severity: Severity.fromString(filter.severity),
  };
}
