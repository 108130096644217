import { MGAInformationMap } from '@breathelife/types';

export enum MGAIds {
  bartellFinancialPartners = 'bartellFinancialPartners',
  danielAdvisorAgency = 'danielAdvisorAgency',
  schultzBrokersGroup = 'schultzBrokersGroup',
}

export const MGAList: MGAInformationMap<MGAIds> = {
  [MGAIds.bartellFinancialPartners]: { name: 'Bartell Financial Partners', isInterlocutor: false },
  [MGAIds.danielAdvisorAgency]: { name: 'Daniel Advisor Agency', isInterlocutor: false },
  [MGAIds.schultzBrokersGroup]: { name: 'Schultz Brokers Group', isInterlocutor: false },
};
