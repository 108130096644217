export default {
  home: {
    login: 'Connexion',
    newApplication: 'Commencer une nouvelle application',
  },
  bestMomentToCall: {
    day: 'Jour',
    night: 'Soir',
    all: 'Jour et soir',
  },
  header: {
    defaultName: "Compte d'agent",
    client: 'Votre client:',
    refNo: 'Numéro de référence',
    search: 'Chercher une application',
    exit: "Quitter l'application",
    goToStart: 'Aller à la première étape',
    goToLast: 'Aller à la dernière étape complétée',
    logout: 'Déconnexion',
  },
  leads: {
    tabs: {
      active: 'Prospects actifs',
      assigned: 'Prospects assignés',
      archived: 'Prospects archivés',
    },
    typeOptions: {
      scheduledACall: 'Appel planifié',
      stalledApplication: 'Application inactive',
      qualified: 'Prospect qualifié',
      unqualified: 'Prospect non qualifié',
    },
    stateOptions: {
      unserved: 'Non desservi',
      scheduled: 'Planifié',
      calledOneTime: 'Appelé (1x)',
      calledTwoTime: 'Appelé (2x)',
      calledThreeTime: 'Appelé (3x)',
      calledNoAnswer: 'Appelé sans réponse',
      calledWrongNumber: 'Appelé mauvais numéro',
      accepted: 'Accepté',
      referred: 'Référé',
      referredTerrain: 'Référé (terrain)',
      denied: 'Refusé',
    },
    table: {
      type: 'Type',
      fullName: 'Nom complet',
      language: 'Langue',
      whenToCall: 'Quand appeller',
      phoneNumber: 'Numéro de téléphone',
      smoker: 'Fumeur',
      departureDate: 'Date de départ',
      returnDate: 'Date de retour',
      product: 'Produit',
      status: 'Statut',
    },
    assigned: {
      to: 'Assigné à',
    },
    assign: {
      toMe: "Me l'assigner",
    },
    actions: {
      archive: 'Archiver',
      unarchive: 'Désarchiver',
      unassign: 'Désassigner',
      launchApplication: "Démarrer l'application",
    },
    yes: 'Oui',
    no: 'Non',
    noLeadToShow: 'Aucun prospect à afficher.',
  },
  notes: {
    title: 'Prendre des notes',
    created: 'Créé le {{date}} par {{author}}.\n',
    edited: 'Dernière mise à jour le {{date}} par {{author}}.',
    date: 'DD/MM/YYYY [à] HH:mm',
    save: 'Sauvegarder',
  },
  redirect: {
    error: "Erreur d'authentification:",
    resetPassword: {
      description:
        'Entrez votre adresse courriel ci-dessous, et nous vous enverrons un lien pour mettre à jour votre mot de passe.',
      email: {
        placeholder: 'Entrez votre courriel',
        label: 'Votre courriel',
      },
      submitButton: 'Réinitialiser mon mot de passe',
      message:
        'Si un compte avec votre courriel existe, vous devriez avoir reçu un courriel de notre part. Cliquez sur le lien dans ce courriel pour réinitialiser votre mot de passe',
    },
    tryAgain: 'Réessayer',
    loading: 'Chargement...',
  },
  errors: {
    loginAgain: 'Essayez de vous connecter à nouveau.',
    unableToParse: "Nous n'avons pas pu parser les informations d'authentification.",
    expiredSession: 'Votre session a expiré, veuillez vous reconnecter.',
    missingId: `Ce compte d'agent n'a pas d'ID. Veuillez ajouter le champ 'agent_id' dans les réglages du compte et réessayez`,
    load: "Erreur lors du chargement de l'application",
    assign: 'Une erreur est survenue au moment de vous assigner le prospect',
    unassign: 'Une erreur est survenue au moment de vous désassigner le prospect',
    state: "Une erreur est survenue lors du changement d'état du prospect",
    leads: 'Une erreur est survenue au moment de récupérer les prospects',
    archive: "Une erreur est survenue au moment d'archiver le prospect",
    unarchive: 'Une erreur est survenue au moment de désarchiver le prospect',
  },
};
