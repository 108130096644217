import { Event, Severity } from '@sentry/browser';

import { EventModifierReturnType, DomainFilterConfig } from './Types';

function extractDomain(maybeUrl: string) {
  try {
    const parsedUrl = new URL(maybeUrl);
    return parsedUrl.hostname;
  } catch (error: unknown) {
    // Looks like Jest globals differ from Node globals with jsdom (see https://github.com/facebook/jest/issues/2549). So we
    // can't do `error instanceof Error` for instance.
    //
    // Since the try catch has only two lines, we'll assume that if it throws
    // it's an error of type `TypeError` with message `Invalid URL: $URL`.
    return;
  }
}

export function extractDomainsFromEvent(event: Event): string[] {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames ?? [];

  const domains = frames
    .map((frame) => {
      if (frame.abs_path === undefined) {
        return;
      }

      return extractDomain(frame.abs_path);
    })
    .filter((domain): domain is string => domain !== undefined);

  return domains;
}

export function domainFilter(event: Event, domainFilterConfig: DomainFilterConfig[]): EventModifierReturnType {
  const eventDomains = extractDomainsFromEvent(event);

  for (const eventDomain of eventDomains) {
    for (const { domains, fingerprint, severity } of domainFilterConfig) {
      const modifyEvent = domains.some((filterDomain) => {
        return eventDomain.endsWith(filterDomain);
      });

      if (modifyEvent) {
        return { fingerprint, severity: Severity.fromString(severity) };
      }
    }
  }
}
