export default {
  home: {
    login: 'Login',
    newApplication: 'Start a new application',
  },
  bestMomentToCall: {
    day: 'Day',
    night: 'Night',
    all: 'Day and night',
  },
  header: {
    defaultName: 'Agent account',
    client: 'Your client:',
    refNo: 'Reference number',
    search: 'Search an application',
    exit: 'Exit this application',
    goToStart: 'Go to the first step',
    goToLast: 'Go to the last completed step',
    logout: 'Logout',
  },
  leads: {
    tabs: {
      active: 'Active leads',
      assigned: 'Assigned leads',
      archived: 'Archived leads',
    },
    typeOptions: {
      scheduledACall: 'Scheduled a call',
      stalledApplication: 'Inactive application',
      qualified: 'Qualified lead',
      unqualified: 'Unqualified lead',
    },
    stateOptions: {
      unserved: 'Unserved',
      scheduled: 'Scheduled',
      calledOneTime: 'Called (1x)',
      calledTwoTime: 'Called (2x)',
      calledThreeTime: 'Called (3x)',
      calledNoAnswer: 'Called no answer',
      calledWrongNumber: 'Called wrong number',
      accepted: 'Accepted',
      referred: 'Referred',
      referredTerrain: 'Referred (terrain)',
      denied: 'Denied',
    },
    table: {
      type: 'Type',
      fullName: 'Full name',
      language: 'Lang',
      whenToCall: 'When to call',
      phoneNumber: 'Phone number',
      smoker: 'Smoker',
      departureDate: 'Departure Date',
      returnDate: 'Return Date',
      product: 'Product',
      status: 'Status',
    },
    assigned: {
      to: 'Assigned to',
    },
    assign: {
      toMe: 'Assign to me',
    },
    actions: {
      archive: 'Archive',
      unarchive: 'Unarchive',
      unassign: 'Unassign',
      launchApplication: 'Launch Application',
    },
    yes: 'Yes',
    no: 'No',
    noLeadToShow: 'No lead to show.',
  },
  notes: {
    title: 'Take notes',
    created: 'Created on {{date}} by {{author}}.\n',
    edited: 'Last edited on {{date}} by {{author}}.',
    date: 'DD/MM/YYYY [at] h:mm A',
    save: 'Save',
  },
  redirect: {
    error: 'Error authenticating agent:',
    resetPassword: {
      description: 'Write your email address below, and we will be sending you a link to update your password',
      email: {
        placeholder: 'Type your email',
        label: 'Your email',
      },
      submitButton: 'Reset password',
      message:
        'If an account with your email exists, you should have received an email from us. Click the link in that email to reset your password',
    },
    tryAgain: 'Try Again',
    loading: 'Loading...',
  },
  errors: {
    loginAgain: 'Please try logging in again.',
    unableToParse: 'There was a problem parsing the authentication information.',
    expiredSession: 'Your session expired, please login again.',
    missingId: `This agent account is missing an agent id. Please set the 'agent_id' field in the account's settings and try again.`,
    load: 'Error loading application',
    assign: 'Error assigning lead',
    unassign: 'Error unassigning lead',
    state: 'Error updating application status',
    leads: 'Error while fetching the leads',
    archive: 'Error archiving lead',
    unarchive: 'Error unarchiving lead',
  },
};
