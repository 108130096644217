import React from 'react';

import config from '@breathelife/config/frontend';
import featureFlag, { FeatureFlag } from '@breathelife/feature-flag';
import {
  buildPlatformFeaturesConfig,
  LeadsTableColumnName,
  LaunchApplicationType,
  LeadDetailTab,
  LeadPlatform,
  LeadStatus,
  LeadStatusesKeys,
  LeadTableColumnInfo,
  LeadTableColumnKeys,
  Mga,
  PlatformFeatures,
  LeadPlatformConfig,
} from '@breathelife/lead-platform';
import { Permission, EntityMappingsConfig } from '@breathelife/types';

import { MGAIds, MGAList } from '@direct/common-breathelife';

import { ImageFilenames } from 'Images/Images';

function DocumentationIcon(): React.ReactElement {
  return (
    <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <g stroke='#757575' strokeLinecap='round' strokeLinejoin='round'>
        <path
          d='m8 .5c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5-7.5-3.358-7.5-7.5 3.358-7.5 7.5-7.5z'
          fill='#fbfbfb'
        />
        <path d='m6 6.00001c0-1.10467.896-2 2.00067-1.99934 1.10466 0 2.00003.896 1.99933 2.00067 0 .84733-.53467 1.60267-1.33333 1.88533-.4.14134-.66667.51934-.66667.94334v.67066' />
        <path d='m8 11.5c-.138 0-.25.112-.25.25s.112.25.25.25.25-.112.25-.25-.112-.25-.25-.25z' />
      </g>
    </svg>
  );
}

function buildLeadPlatformConfig(): LeadPlatformConfig {
  let auth0: any = config.get('keys.auth0');
  // @FeatureFlag enableAuthImprovementFlow start
  if (featureFlag.get(FeatureFlag.enableAuthImprovementFlow)) {
    auth0 = {
      clientID: '0dGZtZ9G2SPNDGngHUaM8XlBeVTEg0Fe',
      domain: 'auth.sandbox.getbreathe.life',
      audience: 'https://breathe-sandbox.us.auth0.com/userinfo',
      responseType: 'token id_token',
      scope: 'openid profile email offline_access',
      dbConnection: '',
    };
  }
  // @FeatureFlag enableAuthImprovementFlow end

  return {
    countryCode: config.get('carrier.defaultCountryCode'),
    auth0: auth0,
    unsubToolUrl: config.get('keys.unsubToolUrl'),
    cloudinary: config.get('keys.cloudinary'),
  };
}

function buildFeaturesConfig(featuresConfigFromFeatureFlags?: Partial<PlatformFeatures>): PlatformFeatures {
  return buildPlatformFeaturesConfig(
    // @ts-ignore TODO: Add missing properties
    {
      changeInsuranceScopesForInvitation: {
        enabled: true,
      },
      softwareByWidget: {
        enabled: true,
        softwareByUrl: 'https://www.breathelife.com/',
      },
      productHub: {
        enabled: true,
      },
      userNotificationEmail: {
        enabled: true,
      },
    },
    featuresConfigFromFeatureFlags
  );
}

const logo = ImageFilenames.agent.logoWhite;
const logoCompact = ImageFilenames.agent.logoCompact;

const leadStatuses = {
  new: {
    label: LeadStatusesKeys.new,
  },
  invited: {
    label: LeadStatusesKeys.invited,
  },
  planFinderStarted: {
    label: LeadStatusesKeys.started,
  },
  planFinderCompleted: {
    label: LeadStatusesKeys.progressing,
  },
  engaged: {
    label: LeadStatusesKeys.finalized,
  },
  transactionFlowStarted: {
    label: LeadStatusesKeys.applicationStarted,
  },
  qualified: {
    label: LeadStatusesKeys.applicationCompleted,
  },
  submissionStarted: {
    label: LeadStatusesKeys.submissionInProgress,
  },
  submissionCompleted: {
    label: LeadStatusesKeys.applicationSubmitted,
  },
};

const enabledTabs = {
  [LeadDetailTab.personalInfo]: {
    index: 0,
  },
  [LeadDetailTab.needsAnalysis]: {
    index: 1,
  },
  [LeadDetailTab.products]: {
    index: 2,
  },
  [LeadDetailTab.notes]: {
    index: 3,
  },
  [LeadDetailTab.history]: {
    index: 4,
  },
};

const leadTableColumns: LeadTableColumnInfo[] = [
  {
    name: LeadsTableColumnName.fullName,
    title: LeadTableColumnKeys.clientName,
  },
  {
    name: LeadsTableColumnName.email,
    title: LeadTableColumnKeys.email,
  },
  {
    name: LeadsTableColumnName.createdAt,
    title: LeadTableColumnKeys.creationDate,
    defaultSort: 'desc',
  },
  {
    name: LeadsTableColumnName.assignedToName,
    title: LeadTableColumnKeys.assignedTo,
    permission: Permission.LeadAssignRead,
  },
  {
    name: LeadsTableColumnName.communication,
    title: LeadTableColumnKeys.bestTimeToReach,
  },
  {
    name: LeadsTableColumnName.status,
    title: LeadTableColumnKeys.status,
  },
];

const isEmailActionEnabled = (leadStatus: LeadStatus): boolean => {
  const enabledStatuses: LeadStatus[] = ['new', 'invited', 'planFinderStarted'];
  return enabledStatuses.includes(leadStatus);
};

const mgas: Mga[] = [
  {
    id: MGAIds.bartellFinancialPartners,
    logo: ImageFilenames.agent.bartellFinancialPartners,
    ...MGAList[MGAIds.bartellFinancialPartners],
  },
  {
    id: MGAIds.danielAdvisorAgency,
    logo: ImageFilenames.agent.danielAdvisorAgency,
    ...MGAList[MGAIds.danielAdvisorAgency],
  },
  {
    id: MGAIds.schultzBrokersGroup,
    logo: ImageFilenames.agent.schultzBrokersGroup,
    ...MGAList[MGAIds.schultzBrokersGroup],
  },
];

const externalResources: { title: string; link: string; icon: React.ReactElement<SVGElement> }[] = [
  {
    title: 'See the documentation',
    link: 'https://projects.invisionapp.com/boards/8C3T0D0T2U4/',
    icon: <DocumentationIcon />,
  },
];

function LeadPlatformContainer(): React.ReactElement {
  return (
    <LeadPlatform
      config={buildLeadPlatformConfig()}
      leadStatuses={leadStatuses}
      enabledTabs={enabledTabs}
      leadTableColumns={leadTableColumns}
      carrierInfo={{
        companyName: config.get('carrier.companyName.en'),
        logo,
        logoCompact,
      }}
      isEmailActionEnabled={isEmailActionEnabled}
      // @ts-ignore TODO: fix type
      externalResources={externalResources}
      mgas={mgas}
      features={buildFeaturesConfig({
        loadLanguageSettingsFromDb: {
          enabled: featureFlag.get(FeatureFlag.enableLoadLanguageSettingsFromDb),
        },
        loadCarrierNamesFromDb: {
          enabled: featureFlag.get(FeatureFlag.enableLoadCarrierNamesFromDb),
        },
        loadPublicLinkMetadataFromDb: {
          enabled: featureFlag.get(FeatureFlag.enableLoadPublicLinkMetadataFromDb),
        },
        salesDecisionRules: {
          enabled: featureFlag.get(FeatureFlag.enableSalesDecisionRules),
        },
        questionnaireEditor: {
          enabled: featureFlag.get(FeatureFlag.enableQuestionnaireEditor),
        },
        questionnairePreview: {
          enabled: featureFlag.get(FeatureFlag.enableQuestionnairePreview),
        },
        questionnaireBuilder: {
          enabled: featureFlag.get(FeatureFlag.enableQuestionnaireBuilder),
        },
        importCopyDeck: {
          enabled: featureFlag.get(FeatureFlag.enableImportFromCopyDeck),
        },
        auditLogs: {
          enabled: featureFlag.get(FeatureFlag.enableAuditLogs),
        },
        thirdPartyIntegrations: {
          enabled: featureFlag.get(FeatureFlag.enableThirdPartyIntegrations),
        },
        engineQuestionnaire: {
          enabled: featureFlag.get(FeatureFlag.enableEngineQuestionnaire),
        },
        needsAnalysis: {
          enabled: true,
          optional: false,
        },
        payments: {
          enabled: config.get('features.payments.enabled'),
          serviceProvider: config.get('features.payments.serviceProvider'),
          [config.get<string>('features.payments.serviceProvider')]: config.get(
            `features.payments.${config.get('features.payments.serviceProvider')}`
          ),
        },
        launchApplication: {
          enabled: true,
          type: LaunchApplicationType.assistedApplication,
          signatureType: config.get('features.signatureType.leadPlatform'),
        },
        questionnaireDebugTools: {
          enabled: config.get('debug.displayQuestionnaireFillerToolbar'),
        },
        userSecurityDisclosure: {
          enabled: featureFlag.get(FeatureFlag.enableUserSecurityDisclosure),
        },
        documentTemplate: {
          enabled: featureFlag.get(FeatureFlag.enableDocumentTemplateFeature),
        },
        authImprovementFlow: {
          enabled: featureFlag.get(FeatureFlag.enableAuthImprovementFlow),
        },
        leadCreation: {
          enabled: featureFlag.get(FeatureFlag.enableLeadCreation),
        },
        participantsProcessor: {
          enabled: featureFlag.get(FeatureFlag.enableParticipantsProcessor),
        },
        enableDecoupleESignFlow: {
          enabled: featureFlag.get(FeatureFlag.enableDecoupleESignFlow),
        },
        questionnaireScreen: {
          enabled: config.get('features.questionnaireScreen.enabled'),
          config: config.get('features.questionnaireScreen.config'),
        },
        showBlueprintIdInEditor: {
          enabled: config.get('features.engine.showBlueprintIdInEditor'),
        },
        entityMappings: config.get<EntityMappingsConfig>('features.entityMappings'),
      })}
    />
  );
}

export default LeadPlatformContainer;
