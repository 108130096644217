export default {
  navigation: {
    continue: 'Continue',
  },
  age: {
    ageValue: '{{age}} years old',
    selectAge: 'Select Age',
  },
  referenceNumber: {
    short: 'Ref number',
    long: 'Reference number',
  },
  name: {
    firstName: 'First name',
    lastName: 'Last name',
  },
  heightWeight: {
    height: 'Height',
    weight: 'Weight',
    selectHeight: 'Select your height',
    selectWeight: 'Select your weight',
  },
  cheque: {
    name: 'Check',
    transit: 'Transit No.',
    institution: 'Institution',
    accountNumber: 'Account No.',
    codePlaceholder: 'Enter code',
    accountNumberPlaceholder: 'Enter the account number',
    chequeInformation: 'You can find this information at the bottom of one of your bank cheques.',
  },
  cardPaymentConsent: {
    title: 'Payment Authorization',
    text: `<p>You authorize National Bank Life Insurance Company to charge the applicable premium for this travel insurance contract to your credit card.</p>
    <p>You confirm that you have the right to authorize a payment on your credit card.</p> 
    <p>You authorize National Bank Life Insurance Company to communicate to your financial institution: your name, address, payment information, the purpose and premium amount.</p>`,
  },
  submission: {
    processing: 'Processing',
    thankYou: 'Thank you for your patience',
  },
  errors: {
    appAlreadySubmitted: 'Cannot change your answer on an already submitted application.',
    unexpected: 'An unexpected error has been detected, please try again or contact us.',
    iframe:
      'Credit card payment is currently unavailable due to technical issues. Please go back and select another method of payment.',
  },
  perMonth: '/month',
};
