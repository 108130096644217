import _ from 'lodash';
import React from 'react';

import config from '@breathelife/config/frontend';
import { ConsumerFlow, Features, Layout, ConsumerFlowConfig } from '@breathelife/consumer-flow';
import featureFlag, { FeatureFlag } from '@breathelife/feature-flag';
import { NeedsAnalysisStepId } from '@breathelife/insurance-form-builder';
import { InsuranceScopes, Language, SignatureType } from '@breathelife/types';

import { localizedConfig, shortLocale } from '@direct/frontend-common/dist/Localization/Localizer';

import { ImageFilenames } from 'Images/Images';
import { globalStyle } from 'PlanFinder/Styles/GlobalStyle';
import { colorizeTheme } from 'Themes/Consumer';

import carrierTranslations from '../Localization';

const images = _.merge({}, ImageFilenames) as typeof ImageFilenames;

const consumerFlowConfig: ConsumerFlowConfig = {
  images,
  cloudinary: config.get('keys.cloudinary'),
  displayFullAdvisorNameOnAdvisorGreetingMessage: true,
};

const stepIdMetadataMap = {
  [NeedsAnalysisStepId.quoter]: {
    hideProgressBar: true,
  },
};

const CarrierComponentCustomization = {
  Header: () => null,
  LeadsTableView: () => null,
  ScheduleCallView: () => null,
  LeftPanelInfoView: () => null,
  MobileHeaderInfoView: () => null,
  HomePage: null,
  ErrorPage: null,
};

const features: Features = {
  productPage: {
    enabled: true,
    quoter: {
      enabled: true,
      sliderStep: 10_000,
    },
  },
  externalApplicationAuth: {
    enabled: false,
  },
  selfServeApplication: {
    enabled: false,
  },
  signature: {
    enabled: true,
    type: SignatureType.cryptoSignature,
  },
  payments: {
    enabled: config.get('features.payments.enabled'),
    serviceProvider: config.get('features.payments.serviceProvider'),
    stripe: {
      publicKey: config.get('features.payments.stripe.publicKey'),
      connectedAccountId: config.get('features.payments.stripe.connectedAccountId'),
    },
  },
};

function ConsumerFlowContainer(props: { enableHeaderProductSummary: boolean }): React.ReactElement {
  const IS_TESTCAFE_RUN = !!window.TESTCAFE_RUN;
  const NEW_APPLICATION_BYPASS = IS_TESTCAFE_RUN || config.get('debug.enableNewApplicationRoute');
  const { enableHeaderProductSummary } = props;
  const enableQuestionnaireDebugTools = config.get<boolean>('debug.displayQuestionnaireFillerToolbar');

  return (
    <ConsumerFlow
      colorizeTheme={colorizeTheme}
      enableLoadLanguageSettingsFromDb={featureFlag.get(FeatureFlag.enableLoadLanguageSettingsFromDb)}
      enableLoadCarrierNamesFromDb={featureFlag.get(FeatureFlag.enableLoadCarrierNamesFromDb)}
      enableLoadProductInformationFromDb={featureFlag.get(FeatureFlag.enableLoadProductInformationFromDb)}
      // @ts-ignore TODO: fix type
      globalStyle={globalStyle}
      config={{ ...consumerFlowConfig, enableHeaderProductSummary }}
      layout={Layout.centered}
      features={features}
      carrierInfo={{
        companyName: config.get(`carrier.companyName.${shortLocale()}`),
        logo: _.get(consumerFlowConfig.images, ['planFinder', 'logo']),
        indexPageTitle: localizedConfig('carrier.indexPage.title'),
        productTransitionLoadingDuration: config.get<Record<InsuranceScopes, number>>('ui.productLoadingDuration'),
        advisorTitleFallback: '',
        policyDetailsUrl: '',
      }}
      lastStepsIds={Object.values(config.get<string[]>('carrier.lastStepsIds'))}
      endOfFlowUrl='/plan/products'
      // @ts-ignore TODO: fix type
      localizedStrings={carrierTranslations}
      // @ts-ignore TODO: fix type
      carrierCustomizations={CarrierComponentCustomization}
      enableNewApplicationRoute={NEW_APPLICATION_BYPASS}
      enableQuestionnaireDebugTools={enableQuestionnaireDebugTools}
      stepIdMetadataMap={stepIdMetadataMap}
      carrierLanguages={[Language.en, Language.fr]}
    />
  );
}

export default ConsumerFlowContainer;
