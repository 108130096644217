import React from 'react';

import config from '@breathelife/config/frontend';
import DynamicPdf from '@breathelife/dynamic-pdf';
import { DynamicPdfComponentName, IsoCountryCode, PdfVariant } from '@breathelife/types';

import { text } from '@direct/frontend-common/dist/Localization/Localizer';

import { pdfTextOverrides } from 'Localization/Pdf/overrides';

function DynamicPdfContainer(): React.ReactElement {
  const countryCode = config.get<IsoCountryCode>('carrier.defaultCountryCode');

  return (
    <DynamicPdf
      carrierPdfOptions={{ enabled: false }}
      insuredPdfOptions={{
        title: text('pdf.title'),
        enabled: true,
        variant: PdfVariant.A,
        componentSelection: [
          {
            name: DynamicPdfComponentName.PdfTitle,
          },
          {
            name: DynamicPdfComponentName.SubmissionDate,
          },
          {
            name: DynamicPdfComponentName.ProposedInsuredInformation,
          },
          {
            name: DynamicPdfComponentName.ProductInformation,
          },
          {
            name: DynamicPdfComponentName.Questionnaire,
          },
          {
            name: DynamicPdfComponentName.InsuranceFirmInformation,
          },
          {
            name: DynamicPdfComponentName.ESignatureContainer,
          },
        ],
      }}
      advisorPdfOptions={{
        enabled: true,
        variant: PdfVariant.A,
        componentSelection: [
          {
            name: DynamicPdfComponentName.PdfTitle,
          },
          {
            name: DynamicPdfComponentName.SubmissionDate,
          },
          {
            name: DynamicPdfComponentName.AdvisorInformation,
          },
          {
            name: DynamicPdfComponentName.ESignatureContainer,
          },
        ],
      }}
      countryCode={countryCode}
      pdfTextOverrides={pdfTextOverrides}
    />
  );
}

export default DynamicPdfContainer;
