export default {
  navigation: {
    continue: 'Continuer',
  },
  age: {
    ageValue: '{{age}} ans',
    selectAge: 'Selectionnez votre age',
  },
  referenceNumber: {
    short: 'N° Ref',
    long: 'N° de référence',
  },
  name: {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
  },
  heightWeight: {
    height: 'Taille',
    weight: 'Poids',
    selectHeight: 'Sélectionnez votre taille',
    selectWeight: 'Sélectionnez votre poids',
  },
  cheque: {
    name: 'Chèque',
    transit: 'N° de Transit',
    institution: 'Institution',
    accountNumber: 'N° de Compte',
    codePlaceholder: 'Entrez le n°',
    accountNumberPlaceholder: 'Entrez le n° de compte',
    chequeInformation: 'Vous pouvez trouver ces informations au bas de l’un de vos chèques de banque.',
  },
  cardPaymentConsent: {
    title: 'Authorisation de paiement',
    text: `<p>Vous autorisez Assurance-vie Banque Nationale à prélever la prime applicable à ce contrat d’assurance voyage sur votre carte de crédit.</p>
    <p>Vous confirmez que vous avez le droit de consentir à cette autorisation de paiement sur votre carte de crédit.</p>
    <p>Vous autorisez Assurance-vie Banque Nationale à communiquer à votre institution financière : vos nom, adresse, informations de paiement, ainsi que l’objet et le montant de la prime.</p>`,
  },
  submission: {
    processing: 'En traitement',
    thankYou: 'Merci de votre patience',
  },
  errors: {
    appAlreadySubmitted: 'Votre application a déjà été soumise, vous ne pouvez pas la modifier.',
    unexpected: 'Une erreur inattendu a été detecté, veuillez reessayer ou nous contacter.',
    iframe:
      'Le paiement par carte de crédit est actuellement indisponible dû à un problème technique. Veuillez retourner en arrière et choisir une autre méthode de paiement.',
  },
  perMonth: '/mois',
};
