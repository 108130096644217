import { configureScope, init, Scope } from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import _ from 'lodash';

import config from '@breathelife/config/frontend';
import { SentryConfig } from '@breathelife/logger';

import { createBeforeSend } from './BeforeSend';
import { SentryEventFiltersConfig } from './Types';

export enum ProductName {
  PlanFinder = 'plan-finder',
  TransactionFlow = 'transaction-flow',
  LeadPlatform = 'lead-platform',
  ConsumerFlow = 'consumer-flow',
}

const { GIT_SHA } = process.env;

function parseSentryEventFilters() {
  // TODO: Actually parse it
  return config.get<SentryEventFiltersConfig>('sentryEventFilters');
}

export function initSentry(): void {
  const sentryConfig: SentryConfig = config.get('sentry');
  const sentryEventFilters = parseSentryEventFilters();
  const carrierId: string = config.get('carrier.id');
  const VERSION = `frontend#${carrierId}#${GIT_SHA}`; // Make sure the release match the one from the Sentry Webpack plugin

  if (!sentryConfig.enabled) {
    return;
  }

  init({
    beforeSend: createBeforeSend(sentryEventFilters),
    dsn: sentryConfig.dsn,
    environment: sentryConfig.environment || 'dev',
    release: VERSION,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
      new TracingIntegrations.BrowserTracing({
        beforeNavigate: (context) => {
          return {
            ...context,
            // TODO: leverage react-router to have the right url path
            name: location.pathname
              .replace(/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g, '<UUID>')
              .replace(/\d+/g, '<DIGITS>'),
          };
        },
      }),
    ],
    // TODO: Add custom sampling once we have a better idea of how to optimize tracing: https://docs.sentry.io/platforms/javascript/performance/#verify
    tracesSampleRate: 1.0,
  });

  const featureFlags: { [key: string]: boolean } = config.get('featureFlags');
  const activeFeatureFlags = _.pickBy(featureFlags, (flag: boolean) => flag);

  //@ts-ignore TS2339 TESTCAFE_RUN does not exist on type Window
  const IS_TESTCAFE_RUN = !!window.TESTCAFE_RUN;

  configureScope((scope: Scope) => {
    const tags: { [key: string]: string } = {
      layer: 'frontend',
      carrier: carrierId || '',
    };

    if (IS_TESTCAFE_RUN) tags.testcafe = 'yes';

    scope.setTags(tags);
    scope.setExtras({
      featureFlags: Object.keys(activeFeatureFlags),
    });
  });
}

export function setSentryProduct(productName: ProductName): void {
  configureScope((scope: Scope) => {
    scope.setTag('product', productName);
  });
}
